import { errorRequestHandler, responseRequestHandler, axiosInstance } from './index'

export const getClientes = async (currentPage = 1, count = 15) => {
  try {
    // const response = await axiosInstance.get('/RPF_GetClientes.php')
    const response = await axiosInstance.post('/RPF_GetClientes.php', {
      data: {
        TotItensPage: count,
        CurrentPage: currentPage
      }
    })
    return response.data
  } catch (error) {
    return errorRequestHandler(error)
  }
}

export const deleteCliente = async id => {
  try {
    const response = await axiosInstance.post('/RPF_DeleteCliente.php', { data: { IDCliente: id } })
    return responseRequestHandler(response)
  } catch (error) {
    return errorRequestHandler(error)
  }
}

export const cadastroCliente = async data => {
  try {
    const response = await axiosInstance.post('/RPF_InsertCliente.php', { data })
    return responseRequestHandler(response)
  } catch (error) {
    return errorRequestHandler(error)
  }
}

export const editaCliente = async data => {
  try {
    const response = await axiosInstance.post('/RPF_UpdateCliente.php', { data })
    return responseRequestHandler(response)
  } catch (error) {
    return errorRequestHandler(error)
  }
}

export const getCliente = async id => {
  try {
    const response = await axiosInstance.post('/RPF_GetCliente.php', { data: { IDCliente: id } })
    return responseRequestHandler(response)
  } catch (error) {
    return errorRequestHandler(error)
  }
}

export const searchCliente = async nome => {
  try {
    const response = await axiosInstance.post('/RPF_SearchClientes.php', { data: { Name: nome } })
    return response
  } catch (error) {
    return errorRequestHandler(error)
  }
}

export const cadastroCNPJ = async data => {
  try {
    const response = await axiosInstance.post('/RPF_InsertCNPJ.php', { data })
    return response
  } catch (error) {
    return errorRequestHandler(error)
  }
}

export const deleteCNPJ = async id => {
  try {
    const response = await axiosInstance.post('/RPF_DeleteCNPJ.php', { data: { IDCNPJ: id } })
    return response
  } catch (error) {
    return errorRequestHandler(error)
  }
}
