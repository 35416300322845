import { responseRequestHandler, errorRequestHandler, axiosInstance } from './index'

export const cadastroProdutos = async (ListaProdutos) => {
  try {
    const response = await axiosInstance.post('/RPF_InsertProdutos.php', { data: { ListaProdutos } })
    return responseRequestHandler(response)
  } catch (error) {
    return error.response.data || error.response
  }
}

export const listaProdutos = async (idCliente, currentPage = 1, count = 15) => {
  try {
    const response = await axiosInstance.post('/RPF_GetProdutos.php', {
      data: {
        IDCliente: idCliente,
        TotItensPage: count,
        CurrentPage: currentPage
      }
    })
    return response.data || {}
  } catch (error) {
    return errorRequestHandler(error)
  }
}

export const findProdutosbyCodigo = async (idCliente, codigo, currentPage = 1, count = 15) => {
  try {
    const response = await axiosInstance.post('/RPF_FindProdutosbyCodigoProduto.php', {
      data: {
        IDCliente: idCliente,
        Codigo_Produto: codigo,
        TotItensPage: count,
        CurrentPage: currentPage
      }
    })
    return response.data || {}
  } catch (error) {
    return error.response.data
  }
}

export const findProdutosbyDescricao = async (idCliente, descricao, currentPage = 1, count = 15) => {
  try {
    const response = await axiosInstance.post('/RPF_FindProdutosbyDescricao.php', {
      data: {
        IDCliente: idCliente,
        Descricao_PTBR: descricao,
        TotItensPage: count,
        CurrentPage: currentPage
      }
    })
    return response.data || {}
  } catch (error) {
    return error.response.data
  }
}

export const detalheProduto = async ({ id, idProduto }) => {
  try {
    const response = await axiosInstance.post('/RPF_GetProduto.php', {
      data: {
        IDProduto: Number(idProduto),
        IDCliente: Number(id)
      }
    })
    return responseRequestHandler(response)
  } catch (error) {
    return error
  }
}

export const productPayload = (id, codigoProduto = null) => {
  return {
    Descricao_PTBR: null,
    Descricao_EN: null,
    Descricao_NF: null,
    Codigo_Produto: codigoProduto,
    NCM: null,
    Unidade_Medida_Comercializada: null,
    Linha_Catalago: null,
    Cod_Unid_Peso: null,
    Descricao_Certificado: null,
    Necessita_LI: null,
    IDCliente: Number(id)
  }
}

export const removerProduto = async idProduto => {
  try {
    const response = await axiosInstance.post('/RPF_DeleteProduto.php', {
      data: { IDProduto: Number(idProduto) }
    })
    return responseRequestHandler(response)
  } catch (error) {
    return error
  }
}

export const atualizaProduto = async produto => {
  try {
    const response = await axiosInstance.post('/RPF_UpdateProdutos.php', {
      data: { ListaProdutos: [produto] }
    })
    return responseRequestHandler(response)
  } catch (error) {
    return error
  }
}
