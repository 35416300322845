<template>
  <div>
    <!-- <title-bar :title-stack="titleStack" /> -->
    <hero-bar>
      Processos
      <router-link slot="right" :to="'/processo/novo'" class="button is-primary">
        Novo processo
      </router-link>
    </hero-bar>
    <section class="section is-main-section">

      <card-component title="Processos" class="has-table has-mobile-sort-spaced">
        <b-field class="m-3">
          <b-select v-model="pagination.perPage" @input="perPageChange">
            <option :value="15">15 por página</option>
            <option :value="25">25 por página</option>
            <option :value="50">50 por página</option>
          </b-select>
       </b-field>
        <p
          v-if="isLoading === false && processos.length === 0"
          class="is-size-4 has-text-centered m-4"
        >
          Não há itens para exibir :(
        </p>
        <b-table
          v-else
          :striped="true"
          :hoverable="true"
          :data="processos"
          :loading="isLoading"
          :per-page="pagination.perPage"
          :current-page.sync="pagination.currentPage"
          :backend-pagination="true"
          :total="pagination.total"
          :paginated="true"
        >

          <b-table-column label="Código Principal" field="Referencia" v-slot="props">
           {{ props.row.Referencia }}
          </b-table-column>
          <b-table-column label="Status" field="Status" v-slot="props">
            <b-tag v-if="parseStatus(props.row.Status) === 'Ativo'" type="is-success">{{parseStatus(props.row.Status)}}</b-tag>
            <b-tag v-else type="is-info">{{parseStatus(props.row.Status)}}</b-tag>
          </b-table-column>
          <b-table-column label="CNPJ" field="IDCNPJ" v-slot="props">
            {{ formatCNPJ(props.row.CNPJ.CNPJ) }}
          </b-table-column>
          <b-table-column label="Data de criação" field="DTCreated" v-slot="props">
            {{ formatDate(props.row.DTCreated) }}
          </b-table-column>

          <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
            <div class="buttons is-right">
              <router-link :to="{ name:'processo.detalhes', params: {id: props.row.IDProcesso} }" class="button is-small is-primary">
                <b-icon icon="arrow-right" size="is-small"/>
              </router-link>
            </div>
          </b-table-column>
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'
import {
  getProcessos,
  formatCNPJ,
  formatDate,
  parseStatus,
  notificationError,
  formatPaginationObj
} from '@/core'

export default {
  name: 'Home',
  components: {
    CardComponent,
    HeroBar
  },
  data () {
    return {
      processos: [],
      isLoading: true,
      pagination: formatPaginationObj()
    }
  },
  computed: {
    titleStack () {
      return ['Admin', 'Dashboard']
    }
  },
  methods: {
    parseStatus: (statusCode) => parseStatus(statusCode),
    formatCNPJ: (val) => formatCNPJ(val),
    formatDate: (v) => formatDate(v),
    getProcessos () {
      this.isLoading = true
      getProcessos(this.pagination.currentPage, this.pagination.perPage)
        .then(res => {
          this.processos = res.dados
          this.isLoading = false
          this.pagination = formatPaginationObj(res)
        })
        .catch(() => {
          this.processos = []
          this.isLoading = false
          notificationError(this.$buefy.notification)
        })
    },
    perPageChange () {
      this.getProcessos()
    }
  },
  mounted () {
    this.getProcessos()
  }
}
</script>
