import dayjs from 'dayjs'
import mapValues from 'lodash/mapValues'

// 0 - sucesso
// 1 - sucesso com erro
// 2 - erro

/* eslint-disable */
export function formatCNPJ (v) {
  v = v.replace(/\D/g,'')                           // Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/,'$1.$2')             // Coloca ponto entre o segundo e o terceiro dígitos
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/,'$1.$2.$3') // Coloca ponto entre o quinto e o sexto dígitos
  v = v.replace(/\.(\d{3})(\d)/,'.$1/$2')           // Coloca uma barra entre o oitavo e o nono dígitos
  v = v.replace(/(\d{4})(\d)/,'$1-$2')              // Coloca um hífen depois do bloco de quatro dígitos
  return v
}
/* eslint-enable */

export const formatDate = date => dayjs(date).format('DD/MM/YYYY')

export const parseStatus = statusCode => {
  if (statusCode === '1') return 'Fechado'
  return 'Ativo'
}

export const notificationError = (notificationObject, message = false) => {
  notificationObject.open({
    duration: 7000,
    message: message || 'Ops... Algo de errado aconteceu! Infelizmente não foi possível exibir os dados solicitados.',
    position: 'is-bottom-right',
    type: 'is-danger',
    hasIcon: true
  })
}

export const formatCurrenyBRL = (val) => {
  return Number(val).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

export const resetFields = form => {
  return mapValues(form, item => {
    if (item && typeof item === 'object') return []
    return null
  })
}
