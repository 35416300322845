import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import App from '../App.vue'
import store from '@/store'

import clientes from './clientes'
Vue.use(VueRouter)

const routes = [
  {
    path: '/full-page',
    component: () => import(/* webpackChunkName: "full-page" */ '@/views/FullPage.vue'),
    children: [
      {
        meta: {
          title: 'Login'
        },
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "full-page" */ '@/views/Login.vue')
      }
    ]
  },
  {
    path: '/',
    component: App,
    children: [
      {
        // Document title tag
        // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
        meta: {
          title: 'Dashboard'
        },
        path: '',
        name: 'home',
        component: Home
      },
      clientes,
      {
        meta: {
          title: 'Tables'
        },
        path: '/tables',
        name: 'tables',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "tables" */ '../views/Tables.vue')
      },
      {
        meta: {
          title: 'Forms'
        },
        path: '/forms',
        name: 'forms',
        component: () => import(/* webpackChunkName: "forms" */ '../views/Forms.vue')
      },
      {
        meta: {
          title: 'Cadastro Usuário'
        },
        path: '/usuarios',
        name: 'usuarios',
        component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
      },
      {
        meta: {
          title: 'Cadastro NCM'
        },
        path: '/ncm',
        name: 'ncm',
        component: () => import(/* webpackChunkName: "ncm" */ '../views/NCM.vue')
      },
      {
        meta: {
          title: 'Profile'
        },
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
      },
      {
        meta: {
          title: 'Edit Client'
        },
        path: '/client/:id',
        name: 'client.edit',
        component: () => import(/* webpackChunkName: "client-form" */ '../views/ClientForm.vue'),
        props: true
      },
      {
        meta: {
          title: 'Novo processo'
        },
        path: '/processo/novo',
        name: 'processo.novo',
        component: () => import(/* webpackChunkName: "processo-novo" */ '../views/processo/NovoProcesso.vue'),
        props: true
      },
      {
        meta: {
          title: 'Detalhe processo'
        },
        path: '/processo/:id',
        name: 'processo.detalhes',
        component: () => import(/* webpackChunkName: "processo-detalhe" */ '../views/processo/DetalheProcesso.vue'),
        props: true
      },
      {
        meta: {
          title: 'Cadastro produto'
        },
        path: '/produto/novo',
        name: 'produto.novo',
        component: () => import(/* webpackChunkName: "produto-detalhe" */ '../views/produtos/Cadastro.vue'),
        props: true
      },
      {
        meta: {
          title: 'Detalhe produto'
        },
        path: '/produto/:id',
        name: 'produto.detalhes',
        component: () => import(/* webpackChunkName: "produto-detalhe" */ '../views/DetalheProduto.vue'),
        props: true
      }
    ]
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login') {
    if (store.getters.isLoggedIn) {
      next()
    } else {
      next('/login')
    }
  }
  next()
})

export default router
