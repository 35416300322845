export const formatImportedList = rows => {
  const keys = rows[0]
  const list = rows.filter((item, index) => index > 0)
  const content = list.map(item => {
    if (item.length > 0) {
      const obj = {}
      item.forEach((element, indexItem) => { obj[keys[indexItem]] = element })
      return obj
    }
  }).filter(el => el !== undefined)
  return content
}

export const equalsIgnoreOrder = (a = [], b) => {
  if (a.length !== b.length) return false
  const uniqueValues = new Set([...a, ...b])
  for (const v of uniqueValues) {
    const aCount = a.filter(e => e === v).length
    const bCount = b.filter(e => e === v).length
    if (aCount !== bCount) return false
  }
  return true
}

export const formatPaginationObj = response => ({
  perPage: response?.TotItensPage || 15,
  currentPage: response?.CurrentPage || 1,
  totalPages: response?.TotalPages || 0,
  total: response ? response.TotalItens || response.itemCount : 0
})
