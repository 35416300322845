import clienteApp from '../views/clientes/index.vue'

const clientes = {
  path: '/clientes',
  component: clienteApp,
  children: [
    {
      meta: {
        title: 'Clientes'
      },
      path: '',
      name: 'clientes',
      component: () => import(/* webpackChunkName: "clientes" */ '../views/Clientes.vue')
    },
    {
      meta: {
        title: 'Cadastro cliente'
      },
      path: 'novo',
      name: 'cliente.new',
      component: () => import(/* webpackChunkName: "cliente-cadastro" */ '../views/clientes/Cadastro.vue')
    },
    {
      path: ':id',
      component: clienteApp,
      children: [
        {
          meta: {
            title: 'Detalhe cliente'
          },
          path: '',
          name: 'cliente.detalhes',
          component: () => import(/* webpackChunkName: "cliente-detalhe" */ '../views/clientes/Detalhes.vue'),
          props: true
        },
        {
          meta: {
            title: 'Cadastro produto'
          },
          path: 'cadastro-produto',
          name: 'cliente.cadastroProduto',
          component: () => import(/* webpackChunkName: "cliente-cadastro-produto" */ '../views/clientes/CadastroProduto.vue'),
          props: true
        },
        {
          path: 'produtos',
          component: clienteApp,
          children: [
            {
              meta: {
                title: 'Produtos'
              },
              path: '',
              name: 'cliente.produtos',
              component: () => import(/* webpackChunkName: "cliente-cadastro-produto" */ '../views/clientes/produto/index.vue'),
              props: true
            },
            {
              meta: {
                title: 'Detalhe produto'
              },
              path: ':idProduto',
              name: 'produto.detalhes.cliente',
              component: () => import(/* webpackChunkName: "produto-detalhe" */ '../views/DetalheProduto.vue'),
              props: true
            }
          ]
        }
      ]
    }
  ]
}

export default clientes
