import { errorRequestHandler, axiosInstance } from './index'

export const getNCMs = async (currentPage = 1, count = 15) => {
  try {
    const response = await axiosInstance.post('/RPF_GetNCMs.php', {
      data: {
        TotItensPage: count,
        CurrentPage: currentPage
      }
    })
    return response.data
  } catch (error) {
    return errorRequestHandler(error)
  }
}

export const insertNCM = async (ncm, preco, modo) => {
  const body = {}
  if (Array.isArray(ncm)) {
    body.ListaNCMs = ncm
  } else {
    body.ListaNCMs = [{
      NCM: ncm,
      Preco: preco,
      MododeCalculo: modo
    }]
  }
  try {
    const response = await axiosInstance.post('/RPF_InsertNCMs.php', {
      data: body
    })
    return response.data
  } catch (error) {
    return error.response.data || error.response
  }
}

export const editNCM = async (ncm, preco, modo) => {
  try {
    const response = await axiosInstance.post('/RPF_UpdateNCM.php', {
      data: {
        NCM: ncm,
        Preco: preco,
        MododeCalculo: modo
      }
    })
    return response.data
  } catch (error) {
    return error.response.data || error.response
  }
}

export const deleteNCM = async ncm => {
  try {
    const response = await axiosInstance.post('/RPF_DeleteNCM.php', {
      data: { NCM: ncm }
    })
    return response.data
  } catch (error) {
    return errorRequestHandler(error)
  }
}

export const searchNCM = async (ncm, currentPage = 1, count = 15) => {
  try {
    const response = await axiosInstance.post('/RPF_SearchNCM.php', {
      data: {
        NCM: ncm,
        TotItensPage: count,
        CurrentPage: currentPage
      }
    })
    return response.data
  } catch (error) {
    return errorRequestHandler(error)
  }
}
