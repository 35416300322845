import { session } from '../../core'

const produto = {
  state: {
    hasPendingProducts: sessionStorage.getItem('hasPendingProducts')
      ? JSON.parse(sessionStorage.getItem('hasPendingProducts'))
      : false,
    pendingProducts: sessionStorage.getItem('pendingProducts')
      ? JSON.parse(sessionStorage.getItem('pendingProducts'))
      : { clientID: null, products: [] }
  },
  mutations: {
    setPendingProductStatus (state, payload) {
      state.hasPendingProducts = payload
      session.set('hasPendingProducts', payload)
    },
    setPendingProducts (state, payload) {
      state.pendingProducts = payload
      session.set('pendingProducts', payload)
    }
  },
  getters: {
    hasPendingProducts (state) { return state.hasPendingProducts },
    pendingProducts (state) { return state.pendingProducts }
  },
  actions: {
    HAS_PENDING_PRODUCTS (context, params) {
      context.commit('setPendingProductStatus', params)
    },
    SET_PENDING_PRODUCTS (context, params) {
      context.commit('setPendingProducts', params)
    }
  }
}

export default produto
