import Cookies from 'js-cookie'

const user = {
  state: {
    isLoggedIn: Cookies.get('isLoggedIn')
      ? JSON.parse(Cookies.get('isLoggedIn'))
      : false,
    userData: Cookies.get('userData')
      ? JSON.parse(Cookies.get('userData'))
      : null
  },
  mutations: {
    setIsLoggedIn (state, payload) {
      state.isLoggedIn = payload
    },
    setUserdata (state, payload) {
      state.userData = payload
    }
  },
  getters: {
    isLoggedIn (state) { return state.isLoggedIn },
    userData (state) { return state.userData }
  },
  actions: {
    SET_IS_LOGGED_IN (context, params) {
      context.commit('setIsLoggedIn', params)
      Cookies.set('isLoggedIn', JSON.stringify(params), { expires: 1 / 24 }) // 1h
    },
    SET_USER_DATA (context, params) {
      context.commit('setUserdata', params)
      Cookies.set('userData', JSON.stringify(params), { expires: 1 / 24 }) // 1h
    }
  }
}

export default user
