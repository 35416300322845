import { errorRequestHandler, axiosInstance } from './index'
import Cookies from 'js-cookie'
import store from '@/store'

export const userLogin = async (userName, password) => {
  try {
    const response = await axiosInstance.post('/RPF_LoginUser.php', { data: { Name: userName, Pass: password } })
    if (response.data.result === 0) {
      store.dispatch('SET_IS_LOGGED_IN', true)
      store.dispatch('SET_USER_DATA', response.data.data)
    }
    return response.data
  } catch (error) {
    store.dispatch('SET_IS_LOGGED_IN', false)
    return errorRequestHandler(error)
  }
}

export const userIsLoggedIn = () => {
  if (Cookies.get('isLoggedIn')) return Cookies.get('isLoggedIn')
  return false
}

export const insertUser = async (name, password, type) => {
  try {
    const response = await axiosInstance.post('/RPF_InsertUser.php', {
      data: {
        Name: name,
        Pass: password,
        Tipo: type
      }
    })
    return response.data
  } catch (error) {
    return error.response.data || error.response
  }
}

export const getUsers = async () => {
  try {
    const response = await axiosInstance.get('/RPF_GetUsers.php')
    if (response.data.result === 0) {
      return response.data.dados
    }
    return response.data
  } catch (error) {
    return errorRequestHandler(error)
  }
}

export const deleteUser = async id => {
  try {
    const response = await axiosInstance.post('/RPF_DeleteUser.php', {
      data: { IDUser: id }
    })
    return response.data
  } catch (error) {
    return error.response.data || error.response
  }
}

export const editUser = async (id, password, type) => {
  try {
    const response = await axiosInstance.post('/RPF_UpdateUser.php', {
      data: {
        IDUser: id,
        Pass: password,
        Tipo: type
      }
    })
    return response.data
  } catch (error) {
    return error.response.data || error.response
  }
}

export const logout = async () => {
  try {
    store.dispatch('SET_IS_LOGGED_IN', false)
    store.dispatch('SET_USER_DATA', null)
    Cookies.remove('isLoggedIn')
    Cookies.remove('userData')
    return
  } catch (error) {
    console.log(error)
  }
}
