import { errorRequestHandler, responseRequestHandler, axiosInstance } from './index'
import { notifyObj } from './requestHandler'
import { NotificationProgrammatic as Notification } from 'buefy'

export const getProcessos = async (currentPage = 1, count = 15) => {
  try {
    const response = await axiosInstance.post('/RPF_GetProcessos.php', {
      data: {
        TotItensPage: count,
        CurrentPage: currentPage
      }
    })
    return response.data || []
  } catch (error) {
    return errorRequestHandler(error)
  }
}

export const novoProcesso = async (body) => {
  try {
    const response = await axiosInstance.post('/RPF_InsertProcesso.php', { data: body })
    return response.data
  } catch (error) {
    return errorRequestHandler(error)
  }
}

export const getProcesso = async id => {
  try {
    const response = await axiosInstance.post('/RPF_GetProcesso.php', { data: { IDProcesso: id } })
    return responseRequestHandler(response) || {}
  } catch (error) {
    // return errorRequestHandler(error)
    return error.response.data
  }
}

export const updateStatusProcesso = async (status, id) => {
  try {
    const response = await axiosInstance.post('/RPF_UpdateStatusProcesso.php', {
      data: {
        Status: status,
        IDProcesso: id
      }
    })
    return responseRequestHandler(response) || {}
  } catch (error) {
    // return errorRequestHandler(error)
    return error.response.data
  }
}

export const PODownload = async id => {
  try {
    const response = await axiosInstance.post('/RPF_GetPOProcesso.php', {
      data: { IDProcesso: id }
    })
    return response.data || {}
  } catch (error) {
    // return errorRequestHandler(error)
    return error.response.data
  }
}

export const validaDadosProcesso = rows => {
  const isValid = []
  const colsToValidate = ['QTDE', 'PESOTOT']

  colsToValidate.forEach(key => {
    const itemIndex = rows[0].findIndex(val => val === key)
    const itens = rows.filter((item, index) => index > 0).map(item => item[itemIndex])
    const everyIsNumber = itens.every(el => typeof el === 'number')
    isValid.push(everyIsNumber)
    if (!everyIsNumber) {
      Notification.open({
        ...notifyObj,
        message: 'Formato inválido na coluna: ' + key,
        type: 'is-danger'
      })
    }
  })

  return isValid.every(el => el === true)
}

export const getProcessosByClient = async (idClient, currentPage = 1, count = 15) => {
  try {
    const response = await axiosInstance.post('/RPF_GetProcessosbyCNPJandStatus.php', {
      data: {
        IDCliente: idClient,
        TotItensPage: count,
        CurrentPage: currentPage
      }
    })
    return response.data || []
  } catch (error) {
    return errorRequestHandler(error)
  }
}
