<template>
  <div id="app">
    <nav-bar />
    <aside-menu :menu="menu" />
    <router-view />
    <footer-bar />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'

export default {
  name: 'Home',
  components: {
    FooterBar,
    AsideMenu,
    NavBar
  },
  computed: {
    userAdmin () {
      const userData = this.$store.getters.userData
      return userData
        ? userData.Tipo === 'Administrador'
        : false
    },
    menu () {
      const urls = [
        {
          to: '/',
          label: 'Processos',
          icon: 'view-list'
        },
        {
          to: '/clientes',
          label: 'Clientes',
          icon: 'account-circle'
        },
        {
          to: '/ncm',
          label: 'NCM',
          icon: 'label'
        }
      ]

      if (this.userAdmin) {
        urls.push({
          to: '/usuarios',
          label: 'Usuários',
          icon: 'account-supervisor'
        })
      }
      return [
        urls
        // 'General',
        // [
        //   {
        //     to: '/',
        //     icon: 'desktop-mac',
        //     label: 'Dashboard'
        //   }
        // ],
        // 'Examples',
        // [
        //   {
        //     to: '/tables',
        //     label: 'Tables',
        //     icon: 'table',
        //     updateMark: true
        //   },
        //   {
        //     to: '/forms',
        //     label: 'Forms',
        //     icon: 'square-edit-outline'
        //   },
        //   {
        //     to: '/profile',
        //     label: 'Profile',
        //     icon: 'account-circle'
        //   },
        //   {
        //     label: 'Submenus',
        //     subLabel: 'Submenus Example',
        //     icon: 'view-list',
        //     menu: [
        //       {
        //         href: '#void',
        //         label: 'Sub-item One'
        //       },
        //       {
        //         href: '#void',
        //         label: 'Sub-item Two'
        //       }
        //     ]
        //   }
        // ],
        // 'About',
        // [
        //   {
        //     href: 'https://admin-one.justboil.me',
        //     label: 'Premium Demo',
        //     icon: 'credit-card'
        //   },
        //   {
        //     href: 'https://justboil.me/bulma-admin-template/one',
        //     label: 'About',
        //     icon: 'help-circle'
        //   }
        // ]
      ]
    }
  }
}
</script>
