// 0 - sucesso
// 1 - sucesso com erro
// 2 - erro
import axios from 'axios'
import { NotificationProgrammatic as Notification } from 'buefy'

export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})
export const notifyObj = {
  duration: 10000,
  position: 'is-top-right',
  hasIcon: true,
  pauseOnHover: true
}

export const errorRequestHandler = (error) => {
  throw new Error(error)
}

export const responseRequestHandler = res => {
  if (res.data.result === 0) {
    if (res.data.message) {
      reponseHandler(res.data)
    }
    return res.data
  }
  reponseHandler(res.data)
  return res.data
}

const catchErrorList = response => {
  const errorList = []

  if (response.data) {
    Object.keys(response.data).forEach(key => {
      if (key.toLowerCase().includes('error')) {
        response.data[key].forEach(errorContent => {
          if (Object.prototype.hasOwnProperty.call(errorContent, 'message')) {
            errorList.push(errorContent.message)
          }
        })
      }
    })
  }

  return errorList
}
const messageType = (resultCode) => {
  let type
  switch (resultCode) {
    case 2:
      type = 'is-danger'
      break
    case 0:
      type = 'is-success'
      break
    default:
      type = 'is-info'
      break
  }
  return type
}

export const reponseHandler = (response, successCallback = () => null, failCallback = () => null) => {
  if (response.message) {
    let message = [response.message || 'Ops... Algo de errado aconteceu! Infelizmente não foi possível concluir sua ação.']
    if (catchErrorList(response).length) message = [...message, ...catchErrorList(response)]
    Notification.open({
      ...notifyObj,
      message,
      type: messageType(response.result)
    })
  }
  if (response.result === 0) return successCallback()
  return failCallback()
}
